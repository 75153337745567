import { TfReportingParameterGroup } from 'api';
import { AverageSet, ParameterUnitTypeEnum, StatRow } from 'oas';

export interface ParameterUnitTypeInfo {
    unitType: ParameterUnitTypeEnum;
    isAverage: boolean;
}

export interface CustomUnitType {
    group: string;
    list: string[];
    unitInfo: ParameterUnitTypeInfo;
}
export interface ReportingMetric {
    metricName: string;
    metricValue: number;
    unitKey?: string;
    unitTypeInfo?: ParameterUnitTypeInfo;
    hasInvalidData?: boolean;
}

export interface ReportingMetricPercentage extends ReportingMetric {
    metricPercentage: number;
}

export interface StatsModel {
    id?: string;
    stats: StatRow | undefined;
    statType: StatType | string;
    volumeMetrics: ReportingMetric[];
    intensityMetrics: ReportingMetric[];
}

export interface PercentageBreakdownModel {
    id?: string;
    volumeMetrics: ReportingMetricPercentage[];
    intensityMetrics: ReportingMetricPercentage[];
}

export interface AverageSetsModel {
    athleteAverageSet: StatsModel[] | undefined;
    positionAverageSet: StatsModel[] | undefined;
    teamAverageSet: StatsModel | undefined;
    defaultBenchmark: StatsModel;
    comparison: string;
}

export interface AthleteName {
    firstName: string;
    lastName: string;
}

export interface ExtendedAthlete {
    athleteName: AthleteName;
    athleteStats: StatRow;
    position: string;
}

export interface PeriodFilter {
    periodName: string;
    periodIds: string[];
}

export interface ParticipationTag {
    name: string;
    variant:
        | 'successText'
        | 'alertText'
        | 'neutral'
        | 'warningText'
        | 'informationText'
        | 'onboarding'
        | 'clearable'
        | undefined;
}

export interface SelectedAverageSets {
    text: string;
    team: AverageSet | undefined;
    athlete: AverageSet | undefined;
    position: AverageSet | undefined;
}

export enum StatType {
    Activity = 'activity',
    Athlete = 'athlete',
    Period = 'period',
    Team = 'team',
    Date = 'date',
    Week = 'week',
    Season_Week = 'season_week',
    All = 'all',
}

export interface DurationInfo {
    startTime: number;
    endTime: number;
    duration: string;
}

export interface DefaultChartParameterOptions {
    parameters: TfReportingParameterGroup[];
    defaultLength?: number;
    returnEmptySelection?: boolean;
}

export interface DefaultTableParameterOptions {
    parameters: TfReportingParameterGroup[];
    returnEmptySelection?: boolean;
}
